'use client';
import React from 'react';
import { usePathname } from 'next/navigation';
import { useState } from 'react';
import { useCart } from '@/context/cart/CartContext';
const DeleteItemButton = ({ id }) => {
  const pathName = usePathname();
  const [isClicked, setIsClicked] = useState(false);
  const { removeFromCart, deleteQuickbuyItem } = useCart();
  const sessionId = localStorage.getItem('sessionId');
  const handleClick = () => {
    if (pathName === '/checkout') {
      setIsClicked(true);
    } else {
      removeFromCart(id);
    }
  };

  const handleRemove = () => {
    const sessionId = localStorage.getItem('sessionId');
    setIsClicked(false);
    if (sessionId) {
      return deleteQuickbuyItem(id, sessionId, false);
    }
    removeFromCart(id, false);
  };

  const handleSaveforLater = () => {
    const sessionId = localStorage.getItem('sessionId');
    if (sessionId) {
      return setIsClicked(false);
    }
    removeFromCart(id, true);
  }
  const handleClose = (e) => {
    if (e.target.id === 'container') {
      setIsClicked(false);
    }
  };
  return (
    <>
      <div onClick={() => handleClick()} className='flex cursor-pointer p-1 bg-white rounded-full'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth='1.5'
          stroke='currentColor'
          className='h-4 w-4 text-black'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
          />
        </svg>
      </div>
      {isClicked && (
        <div
          id='container'
          onClick={(e) => handleClose(e)}
          className='w-full fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30 backdrop-blur-sm'
        >
          <div
            className={`w-3/4 rounded-lg bg-white p-4 md:w-2/5 lg:1/3 ${isClicked ? 'slide-up' : ''}`}
          >
            <div className='mb-5 flex flex-col items-start'>
              <h1 className='text-start mb-2 text-2xl text-gray-600'>Removing an exquisite piece ? </h1>
              <p className='text-start text-gray-600'>
                Let us ensure your selection meets your highest standards.
                Explore further or reach out for personalized assistance
              </p>
            </div>

            <div className='flex justify-end space-x-2'>
              <button
                onClick={() => handleRemove()}
                className='rounded-lg bg-red-500 px-6 py-2 text-white'
              >
                Remove
              </button>
              <button
                onClick={() => handleSaveforLater()}
                className='rounded-lg bg-black px-6 py-2 text-white'
              >
                {sessionId ? 'Cancel' : 'Save for later'}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DeleteItemButton;
